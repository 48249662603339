/* import __COLOCATED_TEMPLATE__ from './restricted-access.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class TooltipsRestrictedAccess extends Component {
  @service intercomEventService;
  @service permissionsService;
  @service router;
  @service appService;
  @service intl;

  tootipClass = 'restricted-access-tooltip-content';

  get app() {
    return this.appService.app;
  }

  get adminCanGiveThemselvesASeat() {
    return this.app.currentAdmin.currentAppPermissions.can_manage_teammates;
  }

  get isInboxSeatRestriction() {
    return this.args.permission === 'has_inbox_access';
  }

  @action
  addClickEvent(elem) {
    elem
      .querySelector(`.${this.tootipClass}`)
      .addEventListener('click', () => this.requestPermission());
  }

  @action
  requestPermission() {
    if (this.isInboxSeatRestriction && this.adminCanGiveThemselvesASeat) {
      return this.router.transitionTo('apps.app.settings.teammates');
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'permission_requested',
      object: this.args.object,
      permission_key: this.args.permission,
    });

    if (!this.isInboxSeatRestriction) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(this.args.permission);
    } else {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_manage_teammates');
    }
  }
}
