/* import __COLOCATED_TEMPLATE__ from './terms-of-service-notification-component.hbs'; */
/* RESPONSIBLE TEAM: team-web */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { enqueueTask } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';

export default class TermsOfServiceNotification extends Component {
  @service modelDataCacheService;

  get showTermsOfServiceReminder() {
    let { admin, app } = this.args;
    return !admin.terms_of_service_accepted && !app.impersonation_session;
  }

  @enqueueTask
  *acceptTermsOfService() {
    yield ajax({
      url: '/ember/terms_of_service_acceptance_state/accept_latest_terms',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.args.app.id,
      }),
    }).then((response) => {
      this.args.admin.set('terms_of_service_accepted', true);
      this.modelDataCacheService.clear();
    });
  }
}
