/* import __COLOCATED_TEMPLATE__ from './teams-members-selector-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, setDiff, sort, empty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  intl: service(),

  classNames: ['m__team-member-selector', 'u__relative'],
  classNameBindings: ['isExpanded:o__expanded:o__collapsed', 'isDisabled:o__disabled'],

  selectableAdmins: setDiff('app.assignableHumanAdmins', 'selectedAdmins'),
  sortKey: ['name'],
  sortedItems: sort('selectableAdmins', 'sortKey'),

  noSelectedAdmins: empty('selectedAdmins'),

  displayedTeammatesPlaceholderText: computed('intl.locale', 'noSelectedAdmins', function () {
    if (this.noSelectedAdmins) {
      return this.intl.t('settings.teams.team-member-selector.placeholder-for-no-selection');
    }
    return this.intl.t('settings.teams.team-member-selector.placeholder');
  }),
  placeholderText: computed(
    'intl.locale',
    'isPrimary',
    'isSecondary',
    'displayedTeammatesPlaceholderText',
    function () {
      if (this.isPrimary) {
        return this.intl.t('settings.teams.team-member-selector.placeholder-for-primary');
      }
      if (this.isSecondary) {
        return this.intl.t('settings.teams.team-member-selector.placeholder-for-secondary');
      }
      return this.displayedTeammatesPlaceholderText;
    },
  ),
});
